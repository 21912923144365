import {Outlet, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect} from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Container, ListItem} from "@mui/material";
import useAuth from "../hooks/useAuth";
import {loadFull} from "tsparticles";
import Particles from "react-particles";

const drawerWidth = 240;

const allNavItems = [
    {text: "Accounts", url: "/dashboard", priority: 1, roles: ["USER", "ADMIN", "MODERATOR"]},
    {text: "Profile", url: "/dashboard/profile", priority: 2, roles: ["USER", "ADMIN"]},
    {text: "Admin Accounts", url: "/admin/accounts", priority: 3, roles: ["ADMIN"]},
    {text: "Admin Users", url: "/admin/users", priority: 4, roles: ["ADMIN"]},
    {text: "Logout", url: "/logout", priority: 100, roles: ["USER", "ADMIN", "MODERATOR"]}
];

const getNavItems = user => {
    return allNavItems.filter(item => item.roles.some(role => user.roles.includes(role)))
        .sort((a, b) => a.priority - b.priority);
};
const DashboardLayout = () => {
    const navigate = useNavigate();
    const {user} = useAuth();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [navItems, setNavItems] = React.useState([{}]);

    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    useEffect(() => {
        setNavItems(getNavItems(user));
    }, [user]);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleNavigation = (url) => {
        navigate(url);
        if (mobileOpen) {
            handleDrawerToggle();
        }
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2}}>
                CSX
            </Typography>
            <Divider/>
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton
                            onClick={() => handleNavigation(item.url)}
                            sx={{textAlign: 'center'}}
                        >
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar color="transparent" sx={{ backdropFilter: 'blur(4px)' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        variant="h4"
                        component="a"
                        sx={{
                            flexGrow: 1,
                            display: {xs: 'none', sm: 'block'},
                            textDecoration: 'none',
                            color: 'inherit',
                            transition: 'color 0.3s ease-in-out',
                            '&:hover': {
                                color: 'primary.main',
                            },
                        }}
                        className="font-face-azonix"
                        href="/dashboard"
                    >
                        CSX
                    </Typography>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        {navItems.map((item, index) => (
                            <Button
                                key={index}
                                color="primary"
                                variant="outlined"
                                onClick={() => handleNavigation(item.url)}
                                sx={{marginLeft: '10px'}}
                            >
                                {item.text}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Container maxWidth="lg"
                       style={{ marginTop: '64px', marginBottom: '16px', minHeight: `calc(100vh - 96px)`, padding: '16px' }}
                       component="main"
            >
                <Outlet />
            </Container>
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    fullScreen: {
                        enable: true,
                        zIndex: -1
                    },
                    fpsLimit: 120,
                    particles: {
                        number: {
                            value: 250,
                            density: {
                                enable: true
                            }
                        },
                        shape: {
                            type: 'none'
                        },
                        line_linked: {
                            enable: true,
                            distance: 100,
                            color: 'rgba(255,143,0,0.6)',
                            opacity: 0.3,
                            width: 1
                        },
                        move: {
                            enable: true,
                            speed: 1,
                            direction: 'none',
                            random: false,
                            straight: false,
                            out_mode: 'out',
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200
                            }
                        }
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: 'grab',
                                parallax: {
                                    enable: true,
                                    smooth: 10,
                                    force: 60
                                }
                            },
                            resize: true
                        },
                        modes: {
                            grab: {
                                distance: 200,
                                line_linked: {
                                    opacity: 0.2
                                }
                            },
                            bubble: {
                                distance: 400,
                                size: 40,
                                duration: 2,
                                opacity: 0.8,
                                speed: 3
                            },
                            repulse: {
                                distance: 200
                            },
                            push: {
                                particles_nb: 4
                            },
                            remove: {
                                particles_nb: 2
                            }
                        }
                    },
                    retina_detect: true
                }}
            />
        </Box>
    );
};
export default DashboardLayout;