import Routes from "./routes/index"
import {AuthProvider} from "./contexts/AuthContext"
import ThemeCustomization from "./themes";

function App() {
    return (
        <ThemeCustomization>
            <AuthProvider>
                <Routes/>
            </AuthProvider>
        </ThemeCustomization>
    );
}

export default App;
