import {lazy} from "react";
import Loadable from "../pages/util/Loadable";

import RouteGuard from "../utils/RouteGuard";
import DashboardLayout from "../layout/DashboardLayout";

const Accounts = Loadable(lazy(() => import('../pages/DashboardRoutes/AdminRoutes/Accounts')));
const Account = Loadable(lazy(() => import('../pages/DashboardRoutes/AdminRoutes/Account')));
const Create = Loadable(lazy(() => import('../pages/DashboardRoutes/AdminRoutes/Create')));
const Users = Loadable(lazy(() => import('../pages/DashboardRoutes/AdminRoutes/Users')));
const User = Loadable(lazy(() => import('../pages/DashboardRoutes/AdminRoutes/User')));

const AdminRoutes = {
    path: '/admin',
    element: (
        <RouteGuard roles={["USER", "MODERATOR", "ADMIN", "SUPER_ADMIN"]}>
            <DashboardLayout />
        </RouteGuard>
    ),
    children: [
        {
            path: '/admin/accounts',
            element: <Accounts />
        },
        {
            path: '/admin/account/:accountId',
            element: <Account />
        },
        {
            path: '/admin/accounts/create',
            element: <Create />
        },
        {
            path: '/admin/users',
            element: <Users />
        },
        {
            path: '/admin/user/:userId',
            element: <User />
        }
    ]
};

export default AdminRoutes;
