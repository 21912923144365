import {useRoutes} from 'react-router-dom';
import {lazy} from "react";
import Loadable from "../pages/util/Loadable";

import PageRoutes from "./PageRoutes";
import LoginRoutes from "./LoginRoutes";
import DashboardRoutes from "./DashboardRoutes";
import AdminRoutes from "./AdminRoutes";

const NotFoundForward = Loadable(lazy(() => import('../pages/util/notFoundForward')));
const NotFound = Loadable(lazy(() => import('../pages/util/notFound')));
const HashSite = Loadable(lazy(() => import('../pages/util/hashSite')));

export default function ThemeRoutes() {
    return useRoutes([
        PageRoutes,
        LoginRoutes,
        DashboardRoutes,
        AdminRoutes,
        {path: '/:hash', element: <HashSite/>},
        {path: '/404', element: <NotFound/>},
        {path: '/*', element: <NotFoundForward/>}
    ]);
}
