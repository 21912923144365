import {useState, createContext, useEffect} from "react";
import axiosInstance from "../utils/axiosInstance";
import LinearProgress from "@mui/material/LinearProgress";

const AuthContext = createContext();

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
}

export function AuthProvider({children}) {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        const getUser = async () => {
            try {
                const {data} = await axiosInstance.get("/user/", {withCredentials: true});
                setState({...state, user: data, isLoggedIn:true, isInitialized: true})
            } catch (e) {
                setState({...state, user: null, isInitialized: true})
            }
        }
        getUser()
    }, []);

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return (
            <div>
                <LinearProgress color="primary" />
            </div>
        );
    }

    const refreshUser = async () => {
        try {
            const {data} = await axiosInstance.get("/user/", {withCredentials: true});
            setState({...state, user: data, isLoggedIn:true, isInitialized: true})
        } catch (e) {
            setState({...state, user: null, isInitialized: true})
        }
    }

    return (
        <AuthContext.Provider value={{
            ...state,
            refreshUser
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;
