import {lazy} from "react";
import Loadable from "../pages/util/Loadable";

import RouteGuard from "../utils/RouteGuard";
import Index from "../layout/DashboardLayout";

const Accounts = Loadable(lazy(() => import('../pages/DashboardRoutes/Accounts')));
const Account = Loadable(lazy(() => import('../pages/DashboardRoutes/Account')));
const Profile = Loadable(lazy(() => import('../pages/DashboardRoutes/Profile')));
const App = Loadable(lazy(() => import('../pages/DashboardRoutes/App')));

const DashboardRoutes = {
    path: '/dashboard',
    element: (
        <RouteGuard roles={["USER", "MODERATOR", "ADMIN", "SUPER_ADMIN"]}>
            <Index />
        </RouteGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Accounts />
        },
        {
            path: '/dashboard/Account/:accountId',
            element: <Account />
        },
        {
            path: '/dashboard/profile',
            element: <Profile />
        },
        {
            path: '/dashboard/app',
            element: <App />
        }
    ]
};

export default DashboardRoutes;
