import {lazy} from "react";
import Loadable from "../pages/util/Loadable";

import RouteGuard from "../utils/RouteGuard";
import PageLayout from "../layout/PageLayout";
// const Home = Loadable(lazy(() => import('../pages/PageRoutes/Home')));
const Login = Loadable(lazy(() => import('../pages/LoginRoutes/Login')));
const PublicToken = Loadable(lazy(() => import('../pages/PageRoutes/PublicToken')));
const ClaimToken = Loadable(lazy(() => import('../pages/PageRoutes/ClaimToken')));

const PageRoutes = {
    path: '/',
    element: (
        <RouteGuard roles={["ANYONE"]}>
            <PageLayout />
        </RouteGuard>
    ),
    children: [
        {
            path: '/',
            element: <Login />
        },
        {
            path: '/token/:publicToken',
            element: <PublicToken />
        },
        {
            path: '/claim/:claimToken',
            element: <ClaimToken />
        }
    ]
};

export default PageRoutes;
