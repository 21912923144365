import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

export function canView(user, isLoggedIn, needsRoles) {
    if (needsRoles == null) {
        return false;
    }
    if (needsRoles.includes("ANYONE")) {
        return true;
    }
    if (needsRoles.includes("ANONYMOUS") && !isLoggedIn) {
        return true;
    }
    if (user == null || user.roles == null || user.roles.length === 0) {
        return false;
    }
    if (!user.roles.some((v) => needsRoles.indexOf(v) >= 0)) {
        return false;
    }

    return true;
}

const RouteGuard = ({ roles, children }) => {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();

    const shouldRedirect = !canView(user, isLoggedIn, roles);

    useEffect(() => {
        if (shouldRedirect) {
            navigate('/404', { replace: true });
        }
    }, [shouldRedirect, navigate]);

    if (shouldRedirect) {
        return null;
    }

    return children;
};

export default RouteGuard;