import {lazy} from "react";
import Loadable from "../pages/util/Loadable";

import RouteGuard from '../utils/RouteGuard';
import PageLayout from "../layout/PageLayout";

const Login = Loadable(lazy(() => import('../pages/LoginRoutes/Login')));
const Logout = Loadable(lazy(() => import('../pages/LoginRoutes/Logout')));

const LoginRoutes = {
    path: '/',
    element: (
        <RouteGuard roles={["ANYONE"]}>
            <PageLayout />
        </RouteGuard>
    ),
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/logout',
            element: <Logout />
        }
    ]
};

export default LoginRoutes;
